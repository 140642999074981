import { ApiAgentRequest, request } from "../utils/request"
import { useAuthQuery } from "./auth"

export enum DocumentProcessStatus {
  NOT_UPLOADED = 'NOT_UPLOADED', // Before S3 presigned url is used
  DOCUMENT_TYPE_VALIDATION_FAILED = 'DOCUMENT_TYPE_VALIDATION_FAILED',
  DOCUMENT_TYPE_VALIDATION_COMPLETED = 'DOCUMENT_TYPE_VALIDATION_COMPLETED',
  DOCUMENT_DATA_EXTRACTION_FAILED = 'DOCUMENT_DATA_EXTRACTION_FAILED',
  DOCUMENT_DATA_EXTRACTION_COMPLETED = 'DOCUMENT_DATA_EXTRACTION_COMPLETED',
}

export enum ConfidenceLevel {
  HIGH = 'high',
  LOW = 'low',
  MISMATCH = 'mismatch',
  NONE = 'none',
}

type ValueWithConfidence<T> = {
  value: T
  confidence: ConfidenceLevel
}

type PassportExtraction = {
  firstName?: ValueWithConfidence<string>
  familyName?: ValueWithConfidence<string>
  middleName?: ValueWithConfidence<string>
  passportNumber?: ValueWithConfidence<string>
  expiryDate?: ValueWithConfidence<string>
  nationality?: ValueWithConfidence<string>
  birthDate?: ValueWithConfidence<string>
  sex?: ValueWithConfidence<string>
  address?: {
    address?: string
    city?: string
    province?: string
    postalCode?: string
    country?: string
    confidence?: ConfidenceLevel
  }
}

export type Passport = {
  id: string
  type: 'passport'
  attributes: {
    passportNumber?: string
    expiryDate?: string
    fileName: string
    documentProcessStatus?: DocumentProcessStatus
    uploadedAt: string
    processingResults?: {
      documentTypeConfidence?: ConfidenceLevel
      extractedData?: PassportExtraction
    }
  }
}

export type RawGetPassportResponse = {
  data: Passport
}

type GetPassportProps = {
  studentId: string | undefined
  passportId: string | undefined
  refetchCondition?: (data: Passport | undefined) => boolean
}

export function useGetPassport(props: GetPassportProps) {
  const { isLoading, isFetching, data } = useAuthQuery(
    ['passport', props.studentId, props.passportId],
    async () => await getPassport(props.studentId, props.passportId),
    {
      enabled: !!(props.studentId && props.passportId),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: ({ state }) => {
        if (props.refetchCondition?.(state.data?.data)) {
          return 5000
        }

        return false
      },
    },
  )

  return {
    isLoadingPassport: isLoading,
    isFetchingPassport: isFetching,
    passport: data?.data,
  }
}

export async function getPassport(studentId?: string, passportId?: string) {
  return request<RawGetPassportResponse>(
    new ApiAgentRequest(`/students/${studentId}/passports/${passportId}`, {
      method: 'GET',
    }),
    {
      isExpectedResponse: (res): res is RawGetPassportResponse => res,
    },
  )
}
