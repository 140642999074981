import type { UploadFileData } from '../components/Application'
import { ApiAgentRequest, request } from '../utils'
import { useAuthMutation } from './auth'
import { getPassport } from './useGetPassport'
import { useS3Upload } from './useS3Upload'

type UploadPassportParams = {
  studentId: string
  passportDocument: UploadFileData
}

export function useUploadPassport() {
  const { s3UploadAsync } = useS3Upload()

  const { isPending, mutate } = useAuthMutation(async (params: UploadPassportParams) => {
    const response = await createPassport(params.studentId, params.passportDocument)
    const passportUpload = response.data.meta.files[params.passportDocument.fileName].upload

    await s3UploadAsync({
      file: params.passportDocument.file,
      url: passportUpload.url,
      fields: passportUpload.fields,
    })

    return response
  })

  return {
    isUploadingPassport: isPending,
    uploadPassport: mutate,
  }
}

export type RawUploadPassportResponse = {
  data: {
    id: string
    type: 'passport'
    meta: {
      files: Record<
        string,
        {
          upload: {
            url: string
            fields: Record<string, any>
          }
        }
      >
    }
  }
}

async function createPassport(studentId: string, passportDocument: UploadFileData) {
  return request<RawUploadPassportResponse>(
    new ApiAgentRequest(`/students/${studentId}/passports`, {
      method: 'POST',
      body: {
        data: {
          type: 'passport',
          attributes: {
            contentType: passportDocument.contentType,
            fileName: passportDocument.fileName,
          },
        },
      },
    }),
    {
      isExpectedResponse: (res): res is RawUploadPassportResponse => res,
    },
  )
}