import { Flex, Text, createForm } from '@applyboard/crystal-ui'
import { IAdditionalDocumentDialog } from '../types'
import { getDocumentTypeLabel } from '../../../../utils'
import { FileUploadField } from '../../ApplicationForms/FileUploadField'
import { DocumentDialog } from '../DocumentDialog'
import { useState } from 'react'
import { useUpdateRequestedDocuments } from '../../../../hooks'
import { useApplicationFormContext } from '../../ApplicationForms/ApplicationForm'
import { ALLOWED_FILE_TYPES } from '../../../../utils/constants'

type AdditionalDocumentsFormFields = {
  additionalDocuments: Array<{
    id: string
    file: File
  }>
}

const { Form, Field } = createForm<AdditionalDocumentsFormFields>()

export function AdditionalDocumentsDialog(props: IAdditionalDocumentDialog) {
  const [open, setOpen] = useState(false)
  const { pendingFileUploadState } = useApplicationFormContext()
  const { isUpdatingApplication, updateRequestedDocuments } = useUpdateRequestedDocuments({
    applicationId: props.application.id,
    index: props.index,
  })

  const requestedDocuments = props.application.attributes?.requestedDocuments?.[props.id]
  if (!requestedDocuments) {
    return null
  }

  return (
    <DocumentDialog
      document={props.document}
      heading={`Add ${getDocumentTypeLabel(props.document.documentType)}`}
      open={open}
      setOpen={setOpen}
      form={`additional-documents-${props.id}`}
      loading={isUpdatingApplication}
    >
      <Flex direction="column" gap={4}>
        <Text contrast="mid">{props.document.note}</Text>
        <Form
          defaultValues={{
            additionalDocuments: [],
          }}
          onSubmit={data => {
            updateRequestedDocuments({
              dataFiles: pendingFileUploadState,
              documentType: props.document.documentType,
              onSuccess: () => {
                setOpen(false)
              },
              requestedDocuments,
              requestedDocumentsId: props.id,
              sectionReference: props.document.sectionReference,
            })
          }}
          id={`additional-documents-${props.id}`}
        >
          <Field
            as={FileUploadField}
            allowedFileTypes={ALLOWED_FILE_TYPES}
            application={props.application}
            fileType={props.document.documentType}
            label={
              <Text variant={'bodyS'} contrast="mid">
                Supported file formats: PDF, JPEG, PNG
              </Text>
            }
            name={`additionalDocuments`}
            section={props.document.sectionReference as string}
            showHistory={false}
            validate={value => {
              if (!value.length) {
                return 'At least 1 file is required'
              }
              return true
            }}
          />
        </Form>
      </Flex>
    </DocumentDialog>
  )
}
