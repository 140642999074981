import { Card, Flex, Heading, Text } from '@applyboard/crystal-ui'
import { Intent } from '@applyboard/crystal-ui/lib/types/design'
import { ApplicationDetailAdditionalDocuments } from './ApplicationDetailAdditionalDocuments'
import styled from '@emotion/styled'
import { DocumentOutlineIcon } from '@applyboard/ui-icons'
import { ApplicationDetailCard } from './ApplicationDetailCard'
import { ApplicationDetailDecisionLetterHeading } from './ApplicationDetailDecisionLetterHeading'
import { useQueryClient } from '@tanstack/react-query'
import { RawApplicationAggregationResponse, useUpdateApplication } from '../../../hooks'

import { FileData, FileMetaData, DocumentType } from '@applyboard/school-applications-types-lib'
import { ApplicationDetailsIntent } from './ApplicationDetailCard/types'
import { useEffect, useState } from 'react'
import { DecisionResponseType, StudentDecisions } from '@applyboard/school-applications-types-lib'
import { getFile } from './utils'
import { StudentApplication } from '../types'

type ApplicationDetailsProps = Readonly<{
  onOfferConditionsClick: () => void
  programName?: string
  campusName?: string
  intakeName?: string
  application: StudentApplication
  applicationHasDecisions: boolean
}>

function getTitleByType(type: DocumentType): string {
  switch (type) {
    case DocumentType.PRE_OFFER_LETTER:
      return 'Pre-Offer'
    case DocumentType.FINAL_OFFER_LETTER:
      return 'Final Offer'
    case DocumentType.WAITLIST_LETTER:
      return 'Waitlist'
    case DocumentType.DECLINED_LETTER:
      return 'Declined'
    default:
      return '-'
  }
}

function getIntentByOfferStatus(status: DocumentType): ApplicationDetailsIntent {
  switch (status) {
    case DocumentType.PRE_OFFER_LETTER:
    case DocumentType.FINAL_OFFER_LETTER:
      return 'positive'
    case DocumentType.DECLINED_LETTER:
      return 'negative'
    case DocumentType.WAITLIST_LETTER:
      return 'secondary'
    default:
      return 'secondary'
  }
}

function getIntentByDecisionStatus(
  studentDecision?: DecisionResponseType,
): ApplicationDetailsIntent {
  switch (studentDecision) {
    case DecisionResponseType.ACCEPTED:
      return 'positive'
    case DecisionResponseType.REJECTED:
      return 'negative'
    default:
      return 'secondary'
  }
}

function getHelperTextIntentByDecisionOrStatus(
  studentResponse: DecisionResponseType | undefined,
  documentType: DocumentType,
): Intent {
  return studentResponse
    ? getIntentByDecisionStatus(studentResponse)
    : getIntentByOfferStatus(documentType)
}

function getStatusByLetterType(
  file: FileMetaData | null,
  studentDecisions: StudentDecisions,
): DecisionResponseType | undefined {
  if (file && file.type === DocumentType.PRE_OFFER_LETTER) {
    if (studentDecisions) {
      return studentDecisions.preOfferDecision?.studentResponse
    }
    return DecisionResponseType.PENDING
  }
  return undefined
}

function getHelperTextByStatus(
  status: DocumentType,
  studentDecision?: DecisionResponseType,
): string {
  switch (status) {
    case DocumentType.PRE_OFFER_LETTER:
      if (studentDecision && studentDecision === DecisionResponseType.REJECTED) {
        return 'A decision has been made by you to reject this pre-offer letter.'
      }
      return 'Please download the attached pre-offer letter, review the offer conditions, and respond.'
    case DocumentType.FINAL_OFFER_LETTER:
      return 'Please download the attached offer letter, review the offer conditions, and respond.'
    case DocumentType.WAITLIST_LETTER:
      return 'Please download the attached letter, review the offer conditions, and respond.'
    case DocumentType.DECLINED_LETTER:
      return 'We regret to inform you that, after careful consideration of your application, the school has decided to decline your offer.'
    default:
      return '--'
  }
}

function getFilesByType(files: FileData | undefined, type: DocumentType[]) {
  if (!files) {
    return undefined
  }
  return Object.fromEntries(
    Object.entries(files)
      .filter(([, item]) => type.includes(item?.type as DocumentType))
      .sort(([, a], [, b]) => {
        const uploadedAtA = a?.uploadedAt ? new Date(a.uploadedAt).getTime() : 0
        const uploadedAtB = b?.uploadedAt ? new Date(b.uploadedAt).getTime() : 0
        return uploadedAtB - uploadedAtA
      }),
  )
}

export function ApplicationDetails(props: ApplicationDetailsProps) {
  const queryClient = useQueryClient()
  const [decisionLetters, setDecisionLetters] = useState<FileData>()
  const [additionalFiles, setAdditionalFiles] = useState<FileData>()

  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.application.id,
  })

  useEffect(() => {
    setDecisionLetters(
      getFilesByType(props.application?.attributes?.files as FileData, [
        DocumentType.FINAL_OFFER_LETTER,
        DocumentType.WAITLIST_LETTER,
        DocumentType.PRE_OFFER_LETTER,
        DocumentType.DECLINED_LETTER,
      ]),
    )
    setAdditionalFiles(
      getFilesByType(props.application?.attributes?.files as FileData, [
        DocumentType.SCHOOL_ADDITIONAL_DOCUMENT,
      ]),
    )
  }, [props.application])

  const studentDecisions = props.application?.attributes?.studentDecisions

  const handleRejectDialog = {
    // @ts-ignore
    onSubmit: ({ onSuccess, onError, data }) => {
      updateApplication(
        {
          attributes: {
            studentDecisions: {
              preOfferDecision: {
                studentResponse: DecisionResponseType.REJECTED,
                reason: data.reason,
                decisionDate: new Date().toISOString(),
              },
            },
          },
        },
        {
          onSuccess: response => {
            queryClient.setQueryData(
              ['applications', response.data.id],
              (oldData: RawApplicationAggregationResponse): RawApplicationAggregationResponse => {
                return {
                  data: {
                    ...oldData.data,
                    attributes: {
                      ...oldData.data.attributes,
                      application: response.data,
                    },
                  },
                }
              },
            )
            onSuccess()
          },
          onError,
        },
      )
    },
  }

  const handleAcceptDialog = {
    programName: props.programName ?? '',
    campusName: props.campusName ?? '',
    intakeTermName: props.intakeName ?? '',
    // @ts-ignore
    onSubmit: ({ onSuccess, onError }) => {
      updateApplication(
        {
          attributes: {
            studentDecisions: {
              preOfferDecision: {
                studentResponse: DecisionResponseType.ACCEPTED,
                decisionDate: new Date().toISOString(),
              },
            },
          },
        },
        {
          onSuccess: response => {
            queryClient.setQueryData(
              ['applications', response.data.id],
              (oldData: RawApplicationAggregationResponse): RawApplicationAggregationResponse => {
                return {
                  data: {
                    ...oldData.data,
                    attributes: {
                      ...oldData.data.attributes,
                      application: response.data,
                    },
                  },
                }
              },
            )

            onSuccess()
          },
          onError,
        },
      )
    },
  }

  return (
    <Flex direction="column" gap={6}>
      <Card aria-label="Decision Letter">
        <Card.Header>
          <ApplicationDetailDecisionLetterHeading />
        </Card.Header>
        <Card.Divider mt={2} mb={3} />
        <Flex direction="column" gap={6}>
          {decisionLetters
            ? Object.keys(decisionLetters).map((key: string) =>
                decisionLetters[key] ? (
                  <ApplicationDetailCard
                    key={`decisionLetters-${key}`}
                    title={getTitleByType(decisionLetters[key]?.type as DocumentType)}
                    intent={getIntentByOfferStatus(decisionLetters[key]?.type as DocumentType)}
                    issuedOn={new Date(decisionLetters[key]?.uploadedAt || Date.now())}
                    status={getStatusByLetterType(
                      decisionLetters[key],
                      studentDecisions as StudentDecisions,
                    )}
                    statusChangedOn={
                      decisionLetters[key]?.type === DocumentType.PRE_OFFER_LETTER &&
                      studentDecisions?.preOfferDecision?.decisionDate
                        ? new Date(studentDecisions?.preOfferDecision?.decisionDate)
                        : undefined
                    }
                    file={getFile(key, props.application)}
                    onOfferConditionsClick={
                      decisionLetters[key]?.type === DocumentType.PRE_OFFER_LETTER ||
                      decisionLetters[key]?.type === DocumentType.FINAL_OFFER_LETTER ||
                      decisionLetters[key]?.type === DocumentType.WAITLIST_LETTER
                        ? props.onOfferConditionsClick
                        : undefined
                    }
                    helperText={getHelperTextByStatus(
                      decisionLetters[key]?.type as DocumentType,
                      studentDecisions?.preOfferDecision?.studentResponse,
                    )}
                    helperTextIntent={getHelperTextIntentByDecisionOrStatus(
                      studentDecisions?.preOfferDecision?.studentResponse,
                      decisionLetters[key]?.type as DocumentType,
                    )}
                    loading={isUpdatingApplication}
                    acceptDialog={
                      decisionLetters[key]?.type === DocumentType.PRE_OFFER_LETTER ||
                      studentDecisions?.finalOfferDecision?.studentResponse ===
                        DecisionResponseType.PENDING
                        ? handleAcceptDialog
                        : null
                    }
                    rejectDialog={
                      decisionLetters[key]?.type === DocumentType.PRE_OFFER_LETTER ||
                      studentDecisions?.finalOfferDecision?.studentResponse ===
                        DecisionResponseType.PENDING
                        ? handleRejectDialog
                        : null
                    }
                    documentType={decisionLetters[key]?.type}
                  />
                ) : null,
              )
            : null}
        </Flex>
      </Card>

      <Card aria-label="Additional Documents Received">
        <Card.Header>
          <Flex gap={3}>
            <IconWrapper aria-hidden>
              <DocumentOutlineIcon />
            </IconWrapper>
            <Flex direction="column" gap={2}>
              <Heading level={2} variant="titleS">
                Additional Documents Received
              </Heading>
              <Text contrast="mid">
                The following documents have been shared by the school. Please download them, as
                they contain important information that will assist you in the next steps of the
                process.
              </Text>
            </Flex>
          </Flex>
        </Card.Header>
        <Card.Divider mt={2} mb={3} />
        <ApplicationDetailAdditionalDocuments
          files={additionalFiles as FileData}
          application={props.application}
        />
      </Card>
    </Flex>
  )
}

const IconWrapper = styled.span({
  display: 'flex',
  paddingTop: '4px',
})
