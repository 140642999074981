import { z } from 'zod'
import {
  DocumentTags,
  EducationLevel,
  LanguageExamAvailability,
  RelationshipType,
  TimelineTabs,
} from '../../../utils/enums'
import { StudentApplication } from '../types'
import { ApplicationState } from '../../../hooks'

type isTabCompletedProps = {
  index: number
  application: StudentApplication
}

export function firstTabIncomplete(app: StudentApplication): number {
  const tabs = Object.values(TimelineTabs)
    .map(itm => +itm)
    .filter(v => !isNaN(v))

  return (
    tabs.find(
      tab => !isTabCompleted({ application: app, index: tab - 1 }) || tab === tabs[tabs.length - 1],
    ) ?? 1
  )
}

export function isTabCompleted(props: isTabCompletedProps) {
  if (!props.application) {
    return false
  }

  if (props.application.attributes?.applicationState !== ApplicationState.DRAFT) {
    return true
  }

  switch (props.index + 1) {
    case TimelineTabs.PERSONAL_INFORMATION: {
      const personalInformationSchema = z.object({
        basicPersonalInformation: z.object({
          givenName: z.string().min(1),
          gender: z.string(),
          dateOfBirth: z.coerce.date().max(new Date()),
          nationality: z.string(),
        }),
        addresses: z.object({
          residentialAddress: z.object({
            country: z.string(),
            addressLine1: z.string().min(1),
            province: z.string().min(1),
            city: z.string().min(1),
          }),
          mailingAddress: z.object({
            country: z.string(),
            addressLine1: z.string().min(1),
            province: z.string().min(1),
            city: z.string().min(1),
          }),
        }),
      })

      const statusAndCitizenshipSchema = z.object({
        statusAndCitizenship: z.object({
          passports: z.record(
            z.object({
              passportId: z.string().min(1),
            }),
          ),
          destinationCountryStatus: z.string(),
        }),
      })

      const destinationCountryFileSchema = z
        .record(
          z.object({
            type: z.nativeEnum(DocumentTags),
            uploadStatus: z.string().optional(),
          }),
        )
        .refine(data => {
          return Object.values(data).some(
            file => file.type === DocumentTags.DESTINATION_COUNTRY_STATUS,
          )
        })

      return (
        personalInformationSchema.safeParse(props.application.attributes.personalInformation)
          .success &&
        statusAndCitizenshipSchema.safeParse(props.application.attributes).success &&
        (props.application.attributes.statusAndCitizenship?.destinationCountryStatus ===
          'NO_STATUS' ||
          destinationCountryFileSchema.safeParse(props.application?.attributes?.files).success)
      )
    }

    case TimelineTabs.CONTACT_INFORMATION: {
      const contactInformationSchema = z.object({
        contactInformation: z.object({
          phoneNumber: z.string().min(1),
        }),
        emergencyContactSchema: z.union([
          z.object({
            email: z.string().email(),
            name: z.string().min(1),
            phoneNumber: z.string().min(1),
            relationship: z.nativeEnum(RelationshipType),
          }),
          z.undefined(),
        ]),
      })

      return contactInformationSchema.safeParse(props.application.attributes.personalInformation)
        .success
    }

    case TimelineTabs.EDUCATION_HISTORY: {
      const educationHistorySchema = z.object({
        educationHistory: z.record(
          z.object({
            educationLevel: z.nativeEnum(EducationLevel),
            educationCountry: z.string(),
            credentialReceived: z.string().min(1),
            startDate: z.coerce.date(),
            endDate: z.coerce.date(),
          }),
        ),
        files: z
          .record(
            z.object({
              type: z.nativeEnum(DocumentTags),
              uploadStatus: z.string().optional(),
            }),
          )
          .refine(data => {
            return Object.values(data).some(
              file =>
                file.type === DocumentTags.TRANSCRIPTS || file.type === DocumentTags.CERTIFICATE,
            )
          }),
      })

      return educationHistorySchema.safeParse(props.application.attributes).success
    }

    case TimelineTabs.LANGUAGE_PROFICIENCY: {
      const defaultLanguageProficiencySchema = z.object({
        hasLanguageProficiency: z.enum([
          LanguageExamAvailability.DONT_NEED,
          LanguageExamAvailability.WILL_HAVE,
        ]),
      })

      const proofTypeOther = z.object({
        testType: z.literal('OTHER'),
        description: z.string().max(200),
      })

      const haveLanguageProficiencySchema = z.object({
        hasLanguageProficiency: z.literal(LanguageExamAvailability.HAVE),
        languageProficiencyData: z.record(
          z.discriminatedUnion('testType', [
            proofTypeOther,
            z.object({
              certificateNumber: z.string().min(1),
              testDate: z.coerce.date(),
              testType: z.enum(['IELTS', 'PTE', 'TOEFL']),
              overallScore: z.coerce.number(),
              subscores: z.object({
                listening: z.number(),
                reading: z.number(),
                speaking: z.number(),
                writing: z.number(),
              }),
            }),
          ]),
        ),
      })

      const languageProficiencySchema = z.object({
        languageProficiency: z.discriminatedUnion('hasLanguageProficiency', [
          defaultLanguageProficiencySchema,
          haveLanguageProficiencySchema,
        ]),
        files: z
          .record(
            z.object({
              type: z.nativeEnum(DocumentTags),
              uploadStatus: z.string().optional(),
            }),
          )
          .refine(data => {
            if (
              props.application?.attributes?.languageProficiency?.hasLanguageProficiency &&
              (props.application?.attributes?.languageProficiency
                ?.hasLanguageProficiency as string) !== LanguageExamAvailability.HAVE
            ) {
              return true
            }

            return Object.values(data).some(
              file =>
                file.type === DocumentTags.LANGUAGE_TEST_IELTS ||
                file.type === DocumentTags.LANGUAGE_TEST_PTE ||
                file.type === DocumentTags.LANGUAGE_TEST_TOEFL ||
                file.type === DocumentTags.LANGUAGE_TEST_OTHER,
            )
          }),
      })

      return languageProficiencySchema.safeParse(props.application.attributes).success
    }

    case TimelineTabs.OTHER_REQUIRED_DOCUMENTS: {
      const additionalDocumentsFileSchema = z.object({
        files: z
          .record(
            z.object({
              type: z.nativeEnum(DocumentTags),
              uploadStatus: z.string().optional(),
            }),
          )
          .refine(data => {
            return Object.values(data).some(
              file =>
                file.type === DocumentTags.ADDITIONAL_DOCUMENT ||
                file.type === DocumentTags.STATEMENT_OF_PURPOSE ||
                file.type === DocumentTags.LETTER_OF_RECOMMENDATION ||
                file.type === DocumentTags.CONSENT_FORM ||
                file.type === DocumentTags.GMAT_SCORE ||
                file.type === DocumentTags.GRE_SCORE ||
                file.type === DocumentTags.RESUME ||
                file.type === DocumentTags.EMPLOYMENT_LETTER ||
                file.type === DocumentTags.VOLUNTEER_LETTER,
            )
          }),
      })

      return additionalDocumentsFileSchema.safeParse(props.application.attributes).success
    }
  }

  return false
}
