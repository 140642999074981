import { format } from 'date-fns'
import { convertTimelessDateStrToLocalDate } from '../../../utils'
import { useState } from 'react'
import { Button, Card, Dialog, Flex, Text } from '@applyboard/crystal-ui'
import { IntakeSearchResource } from '@backend/agents-ui-api/resources/intake-search/types'
import { StudentApplication } from '../../../components/Application/types'

type ChangeProgramDialogProps = {
  currentApplication: StudentApplication['attributes']
  loading: boolean
  onClick: () => void
  onCancel: () => void
  selectedIntake: IntakeSearchResource
}

export function ChangeProgramDialog(props: ChangeProgramDialogProps) {
  const [open, setOpen] = useState(true)

  const applicationSummary = {
    programName: props.currentApplication?.programSelected?.program?.name ?? '',
    intakeTermDate: props.currentApplication?.programSelected?.programIntakeTerm?.startDate
      ? format(
          convertTimelessDateStrToLocalDate(
            props.currentApplication.programSelected?.programIntakeTerm?.startDate,
          ),
          'MMM yyyy',
        )
      : '',
    campusName: props.currentApplication?.programSelected?.campus?.name ?? '',
  }

  return (
    <Dialog
      size="md"
      open={open}
      onOpenChange={open => {
        setOpen(open)
        if (!open) {
          props.onCancel()
        }
      }}
    >
      <Dialog.Heading disabled={props.loading}>Confirm your update</Dialog.Heading>
      <Dialog.Content>
        <Flex gap={3} direction="column">
          <Text as="p">This action will do the following update to your application:</Text>
          <Card variant="highlighted" aria-label="Change program information">
            <section aria-label="Current program information">
              <Text intent="primary" variant="bodyS" contrast="mid">
                <strong>{`Current:`}</strong>
              </Text>
              <Flex gap={1}>
                <Text variant="bodyS">
                  <strong>Program:</strong>
                </Text>
                <Text variant="bodyS">{applicationSummary.programName}</Text>
              </Flex>
              <Flex gap={1}>
                <Text variant="bodyS">
                  <strong>Intake:</strong>
                </Text>
                <Text variant="bodyS">{applicationSummary.intakeTermDate}</Text>
              </Flex>
              <Flex gap={1}>
                <Text variant="bodyS">
                  <strong>Campus:</strong>
                </Text>
                <Text variant="bodyS">{applicationSummary.campusName}</Text>
              </Flex>
            </section>
            <section aria-label="New program information">
              <Text intent="primary" variant="bodyS" contrast="mid">
                <strong>{`New:`}</strong>
              </Text>
              <Flex gap={1}>
                <Text variant="bodyS">
                  <strong>Program:</strong>
                </Text>
                <Text variant="bodyS">{props.selectedIntake.attributes.program.name}</Text>
              </Flex>
              <Flex gap={1}>
                <Text variant="bodyS">
                  <strong>Intake:</strong>
                </Text>
                <Text variant="bodyS">
                  {format(
                    convertTimelessDateStrToLocalDate(
                      props.selectedIntake.attributes.intakeTerm.startsOn,
                    ),
                    'MMM yyyy',
                  )}
                </Text>
              </Flex>
              <Flex gap={1}>
                <Text variant="bodyS">
                  <strong>Campus:</strong>
                </Text>
                <Text variant="bodyS">{props.selectedIntake.attributes.campus.name}</Text>
              </Flex>
            </section>
          </Card>
        </Flex>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton disabled={props.loading} intent="secondary" width={'fill'}>
          Cancel
        </Dialog.CloseButton>
        <Button intent="primary" onClick={props.onClick} width={'fill'} loading={props.loading}>
          Confirm & update
        </Button>
      </Dialog.Actions>
    </Dialog>
  )
}
