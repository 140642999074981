import { ApiAgentRequest, request } from '../utils/request'
import { useAuthMutation } from './auth'

type RawCreateStudentResponse = {
  data: {
    id: string
    type: 'student'
  }
}

export function useCreateStudent() {
  const { isPending, mutate, data } = useAuthMutation(async () => {
    return await request<RawCreateStudentResponse>(
      new ApiAgentRequest('/students', {
        method: 'POST',
        body: undefined
      }),
      {
        isExpectedResponse: (res): res is RawCreateStudentResponse => res,
      },
    )
  })

  return {
    isCreatingStudent: isPending,
    createStudent: mutate,
    student: data?.data || null,
  }
}
