import { ApplicationSanitized } from '@backend/sanitize/application'

/**
 * Function that programmatically determines if the application has a fee.
 */
export function hasApplicationFee(application: ApplicationSanitized | null): boolean {
  const applicationFeeAmount = application?.attributes?.applicationFee?.amount

  return applicationFeeAmount !== undefined && applicationFeeAmount > 0
}
