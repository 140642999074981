import { useAuthMutation } from './auth'
import { ApiAgentRequest, request } from '../utils/request'

type DeletePassportParams = {
  studentId: string
  passportId: string
  applicationId: string | undefined
}

export function useDeletePassport() {
  const { isPending, mutate } = useAuthMutation(async (params: DeletePassportParams) => {
    // If the application doesn't exist, we don't want to pass it in the URL
    const passportEndpoint = `/students/${params.studentId}/passports/${params.passportId}`
    const pathParams = params.applicationId ? `?applicationId=${params.applicationId}` : ''
    const url = passportEndpoint + pathParams
      
    return await request(
      new ApiAgentRequest(url, {
        method: 'DELETE',
      }),
      {
        isExpectedResponse: (res): res is any => true,
      },
    )
  })

  return {
    isDeletingPassport: isPending,
    deletePassport: mutate,
  }
}
