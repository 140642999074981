import { useEffect, useState } from 'react'
import { Loading } from '../../../components/Loading'
import { useGetIntakeSearch, useMediaQuery, useProgramFilterSearchParams } from '../../../hooks'
import { ProgramListDesktop } from './ProgramListDesktop/ProgramListDesktop'
import { ProgramListMobile } from './ProgramListMobile/ProgramListMobile'
import { IntakeSearchResource } from '@backend/agents-ui-api/resources/intake-search/types'
import { ChangeProgramDialog } from '../ChangeProgramDialog'
import { StudentApplication } from '../../../components/Application/types'

type ProgramListProps = {
  loading: boolean
  onSubmit: (intake: IntakeSearchResource) => void
  currentApplication: StudentApplication['attributes']
}

export function ProgramList(props: ProgramListProps) {
  const { filters, updateFilters, clearFilters } = useProgramFilterSearchParams()
  const [selectedIntakeId, setSelectedIntakeId] = useState<string>()
  const [selectedIntake, setSelectedIntake] = useState<IntakeSearchResource>()
  const {
    intakeSearch,
    intakeSearchMeta,
    isLoadingIntakeSearch,
    refetchIntakeSearch,
    isFetchingIntakeSearch,
    isStaleIntakeSearch,
  } = useGetIntakeSearch({
    pageNumber: Number(filters.pageNumber),
    perPage: Number(filters.perPage),
    campuses: filters.campuses,
    intakeTerms: filters.startDates,
    statuses: filters.statuses,
    q: filters.q,
  })
  const { isMobile } = useMediaQuery()

  useEffect(() => {
    clearFilters()
  }, [])

  useEffect(() => {
    if (isStaleIntakeSearch) {
      refetchIntakeSearch()
    }
  }, [isStaleIntakeSearch, refetchIntakeSearch])

  if (isLoadingIntakeSearch || isFetchingIntakeSearch) {
    return <Loading />
  }

  return (
    <section aria-label="program list">
      {isMobile && (
        <ProgramListMobile
          intakeSearch={intakeSearch}
          intakeSearchMeta={intakeSearchMeta}
          onSubmit={selectedIntake => {
            setSelectedIntake(selectedIntake)
            if (!props.currentApplication) {
              props.onSubmit(selectedIntake)
            }
          }}
          updateFilters={updateFilters}
          setSelectedIntakeId={setSelectedIntakeId}
          loading={props.loading}
          selectedIntakeId={selectedIntakeId}
          currentApplication={props.currentApplication}
        />
      )}

      {!isMobile && (
        <ProgramListDesktop
          intakeSearch={intakeSearch}
          intakeSearchMeta={intakeSearchMeta}
          onSubmit={selectedIntake => {
            setSelectedIntake(selectedIntake)
            if (!props.currentApplication) {
              props.onSubmit(selectedIntake)
            }
          }}
          updateFilters={updateFilters}
          setSelectedIntakeId={setSelectedIntakeId}
          loading={props.loading}
          selectedIntakeId={selectedIntakeId}
          currentApplication={props.currentApplication}
        />
      )}

      {selectedIntake && props.currentApplication && (
        <ChangeProgramDialog
          currentApplication={props.currentApplication}
          selectedIntake={selectedIntake}
          loading={props.loading}
          onClick={() => {
            props.onSubmit(selectedIntake)
          }}
          onCancel={() => setSelectedIntake(undefined)}
        />
      )}
    </section>
  )
}
