import { useQueryClient } from '@tanstack/react-query'
import { CreateApplicationRequest } from '@backend/agents-ui-api/routes/applications/POST/types'
import { StudentApplication } from '../../components/Application/types'
import { ApiAgentRequest, request } from '../../utils'
import { useAuthMutation } from '../auth'
import { useS3Upload } from '../useS3Upload'
import type { UploadFileData } from '../../components/Application'
import type { RawApplicationAggregationResponse, RawApplicationResponse } from './useGetApplication'
import { stripFileFromData } from '../../utils/stripFileFromData'

export type CreateApplicationParams = {
  data: CreateApplicationRequest['data']
  files?: Record<string, UploadFileData | null>
}

type RawApplicationsResponse = {
  data: StudentApplication
}

async function CreateApplication(data: CreateApplicationRequest) {
  return request<RawApplicationsResponse>(
    new ApiAgentRequest('/applications', {
      method: 'POST',
      body: {
        ...data,
      },
    }),
    {
      isExpectedResponse: (res): res is RawApplicationsResponse => res,
    },
  )
}

export function useCreateApplication() {
  const { s3UploadAsync } = useS3Upload()
  const queryClient = useQueryClient()

  const { isPending, mutate, mutateAsync } = useAuthMutation(
    async (params: CreateApplicationParams) => {
      // First strip file objects to prepare file metadata for the initial create request
      const fileData = stripFileFromData(params.files)
      console.log('fileData', fileData)
      
      console.log('BEFORE params.data', params.data);

      const data: CreateApplicationRequest = {
        data: {
          ...params.data,
          attributes: {
            ...params.data.attributes,
            ...(fileData ? { files: fileData } : {}),
          },
        }
      }

      // Include file metadata in the create request
      console.log('AFTER data', data)

      // Create application to get an ID
      const response = await CreateApplication(params)
      const applicationId = response.data.id
      console.log('applicationId', applicationId)

      // If we have files, handle the upload process
      if (params.files && fileData && 'meta' in response.data && 'files' in response.data.meta) {
        console.log('response.data.meta.files', response.data.meta.files)
        const fileParams = params.files as Record<string, UploadFileData | null>
        const fileMeta = response.data.meta.files
        const filesToUpload = Object.keys(fileParams).filter(fileId => {
          return fileParams[fileId] !== null && fileMeta[fileId]?.upload
        })

        const failedUploads: Record<string, null> = {}
        const s3Uploads = filesToUpload.map(fileId => {
          const upload = fileMeta[fileId].upload
          if (upload?.url && upload?.fields) {
            return s3UploadAsync({
              file: fileParams[fileId]?.file as File,
              url: upload.url,
              fields: upload.fields,
            }).catch(response => {
              failedUploads[fileId] = null
              throw new Error(response)
            })
          }

          failedUploads[fileId] = null
        })

        await Promise.allSettled(s3Uploads)

        if (Object.keys(failedUploads).length) {
          throw new Error('Some files failed to upload, please try again')
        }

        // Poll for file upload completion
        const result = await pollForFileUploadCompletion({
          applicationId,
          fileIds: filesToUpload,
          queryClient,
          options: {
            maxRetries: 150,
            retryFn: (retries: number) => {
              return Math.min(4000, 2 ** retries * 500)
            },
          },
        })

        return result
      }

      return response
    },
  )

  return {
    isCreatingApplication: isPending,
    createApplication: mutate,
    createApplicationAsync: mutateAsync,
  }
}

// Extracted the polling function to use with the application ID we get from create
async function pollForFileUploadCompletion({
  applicationId,
  fileIds,
  queryClient,
  options,
}: {
  applicationId: string
  fileIds: string[]
  queryClient: ReturnType<typeof useQueryClient>
  options: {
    maxRetries: number
    retryFn: (retries: number) => number
  }
}) {
  let retryCount = 0

  function areFilesInUploadedState(applicationResponse: RawApplicationResponse, fileIds: string[]) {
    const files = applicationResponse.data.attributes?.files
    if (!files) return false
    return (
      fileIds.filter(id => {
        return files[id]?.uploadStatus !== 'UPLOADED'
      }).length === 0
    )
  }

  function sleep(time: number) {
    return new Promise(resolve => setTimeout(resolve, time))
  }

  async function getApplication(): Promise<RawApplicationResponse> {
    await queryClient.invalidateQueries({
      queryKey: ['applications', applicationId],
    })
    const applicationAggregationResponse = queryClient.getQueryData([
      'applications',
      applicationId,
    ]) as RawApplicationAggregationResponse

    return {
      data: applicationAggregationResponse.data.attributes.application,
    }
  }

  let applicationResponse = await getApplication()

  while (
    !areFilesInUploadedState(applicationResponse, fileIds) &&
    retryCount < options.maxRetries
  ) {
    await sleep(options.retryFn(retryCount))
    applicationResponse = await getApplication()
    retryCount++
  }

  if (retryCount >= options.maxRetries) {
    throw new Error('There was a problem processing your file upload')
  }

  return applicationResponse
}
