// temp function to deal with optional types in passport object

import { PassportInformation } from "@applyboard/school-applications-types-lib";
import { SetAllFieldsOptional } from "@applyboard/school-backend-lib";

export function getPassportValues(passportData: SetAllFieldsOptional<PassportInformation | null> | undefined): { passportNumber: string, passportExpiry: string } {
    if (passportData && 'passportNumber' in passportData && passportData.passportNumber &&  'passportExpiry' in passportData && passportData.passportExpiry) {
        return { passportNumber: passportData.passportNumber, passportExpiry: passportData.passportExpiry }
    }

    return { passportNumber: '', passportExpiry: '' }
}