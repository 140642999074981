import { Dispatch, SetStateAction } from 'react'
import { Button, Dialog, Flex, Text, useToasts } from '@applyboard/crystal-ui'
import { useDeletePassport } from '../../../hooks/useDeletePassport'
import { useQueryClient } from '@tanstack/react-query'

type RemovePassportDialogProps = {
  open: boolean
  onOpenChange: Dispatch<SetStateAction<boolean>>
  onConfirm: () => void
  passportId: string
  applicationId: string
  studentId: string
}

export function RemovePassportDialog(props: Readonly<RemovePassportDialogProps>) {
  const queryClient = useQueryClient()
  const { deletePassport, isDeletingPassport } = useDeletePassport()
  const toast = useToasts()

  return (
    <Dialog open={props.open} onOpenChange={props.onOpenChange}>
      <Dialog.Heading>Confirm Passport Removal</Dialog.Heading>
      <Dialog.Content>
        <Flex direction="row" gap={4}>
          <img src="/document-warning.svg" alt="Document Warning" />
          <Text>
            Removing this passport will permanently erase its associated data. This action cannot be
            undone. Do you want to proceed?
          </Text>
        </Flex>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
        <Button
          width="fill"
          intent="negative"
          loading={isDeletingPassport}
          onClick={() => {
            deletePassport(
              {
                studentId: props.studentId,
                passportId: props.passportId,
                applicationId: props.applicationId,
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ['applications', props.applicationId],
                  })
                  toast.positive('Your passport has been successfully removed.')
                  props.onConfirm()
                  props.onOpenChange(false)
                },
                onError: (err: any) => {
                  if (err instanceof Error) {
                    toast.negative(new Error(err.message))
                  } else {
                    toast.negative(err)
                  }
                },
              },
            )
          }}
        >
          Proceed anyway
        </Button>
      </Dialog.Actions>
    </Dialog>
  )
}
